.header {
  width: 100%;
  height: 80px;
  background-color: var(--primary);
  z-index: 10;
  justify-content: space-between;
  display: flex;
  position: fixed;
  top: 0;
}

.header__logo {
  font-size: var(--h4);
  font-family: var(--logo-font);
  color: var(--light);
  align-self: center;
  justify-self: center;
  margin-left: 20px;
}

.header__logo a {
  color: var(--light);
  text-decoration: none;
  transition: color .5s;
}

.header__logo a:hover {
  color: var(--tint-accent);
}

.menu {
  height: calc(100vh - 80px);
  background-color: var(--primary);
  color: var(--light);
  width: 100%;
  flex-direction: column;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 80px;
  right: -100%;
}

.show-menu {
  right: 0;
}

.menu__item {
  color: var(--light);
  font-family: var(--body-font);
  font-size: var(--body-1);
  letter-spacing: .5px;
  align-self: center;
  justify-self: center;
  padding: 25px 10px;
  text-decoration: none;
}

.menu__item--button {
  background-color: var(--accent);
  color: var(--primary);
  height: auto;
  box-shadow: 1px 1px 1px var(--accent);
  border-radius: 5px;
  justify-self: center;
  margin-top: 25px;
  padding: 1rem 2rem;
  font-weight: 700;
  transition: background-color .5s;
  display: inline-block;
}

.menu__item--button:hover {
  background-color: var(--tint-accent);
}

.header__hamburger {
  color: var(--light);
  cursor: pointer;
  align-self: center;
  justify-self: center;
  margin-right: 20px;
  font-size: 40px;
  transition: color .5s;
}

.header__hamburger:hover {
  color: var(--accent);
}

.h1 {
  font-size: var(--h1);
  font-family: var(--title);
}

.h2 {
  font-size: var(--h2);
  font-family: var(--title);
}

.h3 {
  font-size: var(--h3);
  font-family: var(--title);
}

.h4 {
  font-size: var(--h4);
  font-family: var(--title);
  color: var(--secondary);
}

.p {
  font-size: var(--body-1);
  font-family: var(--body-font);
  font-weight: var(--medium);
  letter-spacing: .6px;
  line-height: var(--body-line-height);
  margin-top: calc(var(--body-1)  * 1.25);
}

.main {
  height: calc(100vh - 80px);
  background-color: var(--primary);
  color: var(--light);
  margin-top: 80px;
}

.banner {
  height: calc(100vh - 80px);
  padding: 20px;
}

.hello {
  font-family: var(--mono-font);
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 400;
}

.big-heading {
  font-size: 50px;
  font-family: var(--display-font);
  color: var(--regular-color);
  margin-bottom: 1rem;
}

.big-heading--light {
  color: var(--light);
  font-size: 25px;
}

.banner-p {
  font-family: var(--mono-font);
}

.banner__button {
  width: 250px;
  background-color: var(--accent);
  color: var(--primary);
  font-weight: var(--regular);
  font-family: var(--body-font);
  letter-spacing: .5px;
  box-shadow: 1px 1px 1px var(--accent);
  font-size: var(--p);
  border-radius: 5px;
  justify-content: center;
  margin-top: 3rem;
  padding: 15px 0;
  text-decoration: none;
  transition: background-color .5s;
  display: flex;
}

.banner__button:hover {
  background-color: var(--tint-accent);
}

.about {
  background-color: var(--primary);
  padding: 20px;
  scroll-margin-top: 80px;
}

.about__tech-list {
  font-size: var(--body-2);
  margin-top: 8px;
  padding-left: 20px;
}

.tech-list__item {
  list-style-type: none;
}

.tech-list__item:before {
  content: "•";
  color: var(--accent);
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  display: inline-block;
}

.projects {
  background-color: var(--primary);
  padding: 32px 16px;
  scroll-margin-top: 80px;
}

.project-card {
  background-color: var(--secondary);
  border: .1px solid var(--primary);
  color: var(--primary);
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  display: flex;
}

.project-card__title {
  font-size: var(--h5);
  font-family: var(--body-font);
  text-align: center;
  padding: 16px;
  font-weight: 700;
}

.project-card__body {
  font-size: var(--body-1);
  font-family: var(--body-font);
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 15px 16px;
  display: flex;
}

.project-card__body--techs {
  font-family: var(--mono-font);
  font-size: var(--body-2);
  margin-top: 8px;
}

.project-card__footer {
  flex-basis: 50px;
  justify-content: center;
  padding-bottom: 16px;
  display: flex;
}

.project-card__link {
  color: var(--primary);
}

.project-card__link:nth-child(2) {
  margin-left: 16px;
}

.project-card__icon {
  cursor: pointer;
  align-self: center;
  justify-self: center;
  font-size: 30px;
  transition: color .5s;
}

.project-card__icon:hover {
  color: var(--tint-primary);
}

.academic {
  background-color: var(--primary);
  padding: 32px 16px;
  scroll-margin-top: 80px;
}

.academic-list {
  padding-top: 16px;
  padding-left: 16px;
}

.academic-list__item {
  font-size: var(--body-1);
  border-left: 1px dashed #fff;
  padding-bottom: 64px;
  padding-left: 32px;
  list-style-type: none;
  position: relative;
}

.academic-list__item:last-child {
  border-left: none;
  padding-bottom: 16px;
}

.academic-list__item:before {
  content: " ";
  border: 4px solid var(--secondary);
  background: var(--primary);
  height: 25px;
  width: 25px;
  border-radius: 500%;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: -13.5px;
}

.academic-list__item--highlight:before {
  border: 4px solid var(--secondary);
  background: var(--accent);
  height: 30px;
  width: 30px;
  top: -2px;
  left: -16px;
}

.academic-list__item__course-name {
  color: var(--accent);
  display: block;
}

.academic-list__item__school {
  display: block;
}

.academic-list__item__period {
  font-size: var(--body-2);
  display: block;
}

.jobs {
  background-color: var(--primary);
  padding: 32px 16px;
  scroll-margin-top: 80px;
}

.contact {
  padding: 16px 16px 32px;
  scroll-margin-top: 80px;
}

.input-group {
  font-size: var(--body-1);
  font-family: var(--body-font);
  padding: 16px 0;
  display: block;
}

.input-group__input-label {
  display: block;
}

.input-group__text-input {
  width: 100%;
  font-size: var(--body-1);
  font-family: var(--body-font);
  box-shadow: 2px 2px 2px var(--primary);
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 8px;
  padding: 8px;
  display: block;
}

.input-group span {
  color: var(--light-error);
  display: block;
}

.input-group__textarea {
  width: 100%;
  font-size: var(--body-1);
  font-family: var(--body-font);
  resize: none;
  box-shadow: 2px 2px 2px var(--primary);
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 8px;
  padding: 8px;
}

.button {
  background-color: var(--accent);
  color: var(--primary);
  font-size: var(--body-1);
  box-shadow: 1px 1px 1px var(--accent);
  font-weight: var(--regular);
  font-family: var(--body-font);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 16px;
  transition: background-color .5s;
}

.button:hover {
  background-color: var(--tint-accent);
}

.footer {
  padding: 32px 16px;
}

.social-media-box {
  justify-content: center;
  padding: 0 16px;
  display: flex;
}

.social-media-box__link {
  color: var(--accent);
}

.social-media-box__link:nth-child(2), .social-media-box__link:nth-child(3) {
  margin-left: 16px;
}

.social-media-box__icon {
  cursor: pointer;
  align-self: center;
  justify-self: center;
  font-size: 30px;
  transition: color .5s;
}

.social-media-box__icon:hover {
  color: var(--tint-accent);
}

.credit-box {
  font-family: var(--mono-font);
  font-size: var(--body-2);
  text-align: center;
  color: var(--secondary);
  margin-top: 32px;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--accent);
  color: var(--primary);
  text-align: center;
  z-index: 1;
  border-radius: 2px;
  margin: auto;
  padding: 16px;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

#snackbar.show {
  visibility: visible;
  animation: fadein .5s, fadeout .5s 2.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 80px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 80px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 480px) {
  .banner {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (min-width: 480px) {
  .banner {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .big-heading {
    font-size: 64px;
  }

  .big-heading--light {
    font-size: 34px;
  }
}

@media only screen and (min-height: 580px) {
  .banner {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (min-width: 600px) {
  .banner, .about, .projects, .jobs, .academic, .contact, .footer {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .h2 {
    font-size: var(--h2);
  }

  .banner, .about, .projects, .jobs, .academic, .contact, .footer {
    width: 85%;
  }

  .project-list {
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    gap: 16px;
    margin-top: 32px;
    display: grid;
  }

  .project-card {
    margin-top: 0;
  }

  .big-heading--light {
    color: var(--light);
    font-size: 34px;
  }
}

@media only screen and (min-width: 992px) {
  .menu {
    height: auto;
    background-color: var(--primary);
    color: var(--light);
    width: auto;
    flex-direction: row;
    transition: all;
    position: relative;
    top: 0;
    right: 2rem;
  }

  .header__logo {
    margin-left: 2rem;
  }

  .menu__item--button {
    background-color: var(--accent);
    color: var(--primary);
    height: auto;
    box-shadow: 1px 1px 1px var(--accent);
    border-radius: 5px;
    justify-self: center;
    margin-top: 0;
    margin-left: 1rem;
    padding: .5rem 1rem;
    font-weight: 700;
    transition: background-color .5s;
    display: inline-block;
  }

  .header__hamburger {
    display: none;
  }

  .banner {
    width: 80%;
  }

  .big-heading {
    font-size: 66px;
  }

  .banner, .about, .projects, .jobs, .academic, .contact, .footer {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  .banner, .about, .projects, .jobs, .academic, .contact, .footer {
    width: 80%;
    max-width: 740px;
  }
}

/*# sourceMappingURL=index.518bb96e.css.map */
