@import url(variables.css);

/* starts header */
.header {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    background-color: var(--primary);
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.header__logo {
    align-self: center;
    justify-self: center;
    font-size: var(--h4);
    font-family: var(--logo-font);
    color: var(--light);
    margin-left: 20px;
}

.header__logo a {
    color: var(--light);
    text-decoration: none;
    transition: color .5s;
}

.header__logo a:hover {
    color: var(--tint-accent);
}

.menu {
    position: fixed;
    top: 80px;
    height: calc(100vh - 80px);
    right: -100%;
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    color: var(--light);
    width: 100%;
    transition: 0.5s;
}

.show-menu {
    right: 0;
}

.menu__item {
    text-decoration: none;
    color: var(--light);
    font-family: var(--body-font);
    font-size: var(--body-1);
    justify-self: center;
    align-self: center;
    padding: 25px 10px;
    letter-spacing: .5px;
}

.menu__item--button {
    background-color: var(--accent);
    color: var(--primary);
    height: auto;
    padding: 1rem 2rem;
    margin-top: 25px;
    border-radius: 5px;
    font-weight: 700;
    box-shadow: 1px 1px 1px var(--accent);
    transition: background-color .5s;
    justify-self: center;
    display: inline-block;
}

.menu__item--button:hover {
    background-color: var(--tint-accent);
}

.header__hamburger {
    font-size: 40px;
    color: var(--light);
    align-self: center;
    justify-self: center;
    margin-right: 20px;
    cursor: pointer;
    transition: color .5s;
}

.header__hamburger:hover {
    color: var(--accent);
}

/* starts typografy */
.h1 {
    font-size: var(--h1);
    font-family: var(--title);
}

.h2 {
    font-size: var(--h2);
    font-family: var(--title);
}

.h3 {
    font-size: var(--h3);
    font-family: var(--title);
}

.h4 {
    font-size: var(--h4);
    font-family: var(--title);
    color: var(--secondary);
}

.p {
    font-size: var(--body-1);
    font-family: var(--body-font);
    font-weight: var(--medium);
    letter-spacing: .6px;
    line-height: var(--body-line-height);
    margin-top: calc(var(--body-1) * 1.25);
}

/* starts main */
.main {
    margin-top: 80px;
    height: calc(100vh - 80px);
    background-color: var(--primary);
    color: var(--light)
}

.banner {
    height: calc(100vh - 80px);
    padding: 20px;
}

.hello {
    font-family: var(--mono-font);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.big-heading {
    font-size: 50px;
    font-family: var(--display-font);
    color: var(--regular-color);
    margin-bottom: 1rem;
}

.big-heading--light {
    font-size: 25px;
    color: var(--light);
}

.banner-p {
    font-family: var(--mono-font);
}

.banner__button {
    padding: 15px 0;
    width: 250px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    background-color: var(--accent);
    color: var(--primary);
    font-weight: var(--regular);
    font-family: var(--body-font);
    margin-top: 3rem;
    letter-spacing: .5px;
    box-shadow: 1px 1px 1px var(--accent);
    border-radius: 5px;
    font-size: var(--p);
    transition: background-color .5s;
}

.banner__button:hover {
    background-color: var(--tint-accent);
}

/* starts ABOUT */
.about {
    background-color: var(--primary);
    padding: 20px;
    scroll-margin-top: 80px;
}

.about__tech-list {
    padding-left: 20px;
    margin-top: 8px;
    font-size: var(--body-2);
}

.tech-list__item {
    list-style-type: none;
}

.tech-list__item::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--accent);
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    margin-left: -1em;
    /* Also needed for space (tweak if needed) */
}

.projects {
    background-color: var(--primary);
    padding: 32px 16px;
    scroll-margin-top: 80px;
}

.project-card {
    background-color: var(--secondary);
    border: .1px solid var(--primary);
    color: var(--primary);
    border-radius: 5px;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-card__title {
    font-size: var(--h5);
    font-family: var(--body-font);
    font-weight: 700;
    text-align: center;
    padding: 16px;
}

.project-card__body {
    font-size: var(--body-1);
    font-family: var(--body-font);
    padding: 0 15px 16px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.project-card__body--techs {
    margin-top: 8px;
    font-family: var(--mono-font);
    font-size: var(--body-2);
}

.project-card__footer {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    flex-basis: 50px;
}

.project-card__link {
    color: var(--primary);
}

.project-card__link:nth-child(2) {
    margin-left: 16px;
}

.project-card__icon {
    font-size: 30px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    transition: color .5s;

}

.project-card__icon:hover {
    color: var(--tint-primary);
}


/* ends projects*/

/* starts academic*/
.academic {
    padding: 32px 16px;
    background-color: var(--primary);
    scroll-margin-top: 80px;
}

.academic-list {
    padding-top: 16px;
    padding-left: 16px;
}

.academic-list__item {
    list-style-type: none;
    font-size: var(--body-1);
    padding-left: 32px;
    border-left: 1px dashed white;
    position: relative;
    padding-bottom: 64px;
}

.academic-list__item:last-child {
    border-left: 0;
    padding-bottom: 16px;

}

.academic-list__item::before {
    position: absolute;
    left: -13.5px;
    top: 0px;
    content: " ";
    border: 4px solid var(--secondary);
    background: var(--primary);
    border-radius: 500%;
    height: 25px;
    width: 25px;
    transition: all 500ms ease-in-out;
}

.academic-list__item--highlight:before {
    left: -16px;
    top: -2px;
    border: 4px solid var(--secondary);
    background: var(--accent);
    height: 30px;
    width: 30px;
}

.academic-list__item__course-name {
    display: block;
    color: var(--accent);
}

.academic-list__item__school {
    display: block;
}

.academic-list__item__period {
    display: block;
    font-size: var(--body-2);
}


/* ends academic */

/* starts jobs */
.jobs {
    padding: 32px 16px;
    background-color: var(--primary);
    scroll-margin-top: 80px;
}

/* ends jobs */

/* starts contact */
.contact {
    padding: 16px;
    padding-bottom: 32px;
    scroll-margin-top: 80px;
}

.input-group {
    display: block;
    padding: 16px 0;
    font-size: var(--body-1);
    font-family: var(--body-font);
}

.input-group__input-label {
    display: block;
}

.input-group__text-input {
    margin-top: 8px;
    padding: 8px;
    width: 100%;
    font-size: var(--body-1);
    font-family: var(--body-font);
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: 2px 2px 2px var(--primary);
    display: block;
}

.input-group span {
    display: block;
    color: var(--light-error)
}

.input-group__textarea {
    margin-top: 8px;
    width: 100%;
    font-size: var(--body-1);
    font-family: var(--body-font);
    border-radius: 5px;
    padding: 8px;
    resize: none;
    border: none;
    outline: none;
    box-shadow: 2px 2px 2px var(--primary);
}

.button {
    background-color: var(--accent);
    color: var(--primary);
    outline: none;
    border: none;
    padding: 16px;
    font-size: var(--body-1);
    box-shadow: 1px 1px 1px var(--accent);
    border-radius: 5px;
    font-weight: var(--regular);
    font-family: var(--body-font);
    cursor: pointer;
    transition: background-color .5s;
}

.button:hover {
    background-color: var(--tint-accent);
}

/* ends contact */

/* starts footer */
.footer {
    padding: 32px 16px;
}

.social-media-box {
    padding: 0 16px;
    display: flex;
    justify-content: center;
}

.social-media-box__link {
    color: var(--accent);
}

.social-media-box__link:nth-child(2) {
    margin-left: 16px;
}

.social-media-box__link:nth-child(3) {
    margin-left: 16px;
}

.social-media-box__icon {
    font-size: 30px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    transition: color .5s;
}

.social-media-box__icon:hover {
    color: var(--tint-accent);
}


.credit-box {
    margin-top: 32px;
    font-family: var(--mono-font);
    font-size: var(--body-2);
    text-align: center;
    color: var(--secondary)
}

/* ends footer */

/* starts snackbar*/
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    margin: auto;
    /* Divide value of min-width by 2 */
    background-color: var(--accent);
    /* Black background color */
    color: var(--primary);
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1;
    /* Add a z-index if needed */
    left: 50%;
    transform: translateX(-50%);
    /* Center the snackbar */
    bottom: 80px;
    /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 80px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 80px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 80px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 80px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

/*  */

@media only screen and (min-width:320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media only screen and (min-width:480px) {

    /* smartphones, Android phones, landscape iPhone */
    .banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (min-width:480px) {

    /* smartphones, Android phones, landscape iPhone */
    .banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .big-heading {
        font-size: 64px;
    }

    .big-heading--light {
        font-size: 34px;
    }
}

@media only screen and (min-height: 580px) {
    .banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .banner,
    .about,
    .projects,
    .jobs,
    .academic,
    .contact,
    .footer {
        margin: 0 auto;
        width: 90%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .h2 {
        font-size: var(--h2);
    }

    .banner,
    .about,
    .projects,
    .jobs,
    .academic,
    .contact,
    .footer {
        width: 85%;
    }


    .project-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        justify-content: space-around;
        margin-top: 32px;
    }

    .project-card {
        margin-top: 0;
    }



    .big-heading--light {
        font-size: 34px;
        color: var(--light);
    }


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .menu {
        position: relative;
        height: auto;
        top: 0;
        right: 0;
        flex-direction: row;
        background-color: var(--primary);
        color: var(--light);
        width: auto;
        right: 2rem;
        transition: 0s;
    }

    .header__logo {
        margin-left: 2rem;
    }

    .menu__item--button {
        background-color: var(--accent);
        color: var(--primary);
        height: auto;
        padding: .5rem 1rem;
        margin-top: 0;
        margin-left: 1rem;
        border-radius: 5px;
        font-weight: 700;
        box-shadow: 1px 1px 1px var(--accent);
        transition: background-color .5s;
        justify-self: center;
        display: inline-block;
    }

    .header__hamburger {
        display: none;
    }

    .banner {
        width: 80%;
    }

    .big-heading {
        font-size: 66px;
    }

    .banner,
    .about,
    .projects,
    .jobs,
    .academic,
    .contact,
    .footer {
        width: 80%;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .project-list {}

    .banner,
    .about,
    .projects,
    .jobs,
    .academic,
    .contact,
    .footer {
        width: 80%;
        max-width: 740px;
    }
}